<template>
  <section id="dashboard">
    <b-row class="match-height">
      <b-col
        lg="12"
        sm="12"
      >
        <home-info-abatedor />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        lg="12"
        sm="12"
      >
        <home-info-general />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        lg="12"
        sm="12"
      >
        <home-info-devices />
      </b-col>
    </b-row>    
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import HomeInfoAbatedor from './HomeInfoAbatedor.vue'
import HomeInfoGeneral from './HomeInfoGeneral.vue'
import HomeInfoDevices from './HomeInfoDevices.vue'

export default {
  components: {
    BRow,
    BCol,
    HomeInfoAbatedor,
    HomeInfoGeneral,
    HomeInfoDevices,
  },
  data() {
    return {
      data: {},
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-home.scss';
</style>
