<template>
  <div>
    <b-row class="match-height">
      <b-col
        md="12"
        lg="12"
      >
        <b-card no-body>
          <b-card-body>
            <div class="home-top">
              <b-card-title class="home-title mb-0">Abatedor e Ultracongelador Copa</b-card-title>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col
        md="12"
        lg="4"
      >
        <b-card no-body>
          <b-card-body>
            <div class="home-img">
              <img src="https://fakeimg.pl/525x251/" alt="">
            </div>
          </b-card-body>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        lg="8"
      >
        <b-card no-body class="home-status">
          <b-card-body>
            <b-row>
              <b-col class="status-side" cols="12" lg="5">
                <b-card-title class="text-status mb-0">Device status</b-card-title>
                <div class="status-icons">
                  <div class="icon-status">
                    <img src="@/assets/images/icons/ok.png" alt="">
                    <span>ok</span>
                  </div>
                </div>
                <div class="mt-2">
                  <b-card-title class="text-status mb-0">Alertas ativos</b-card-title>
                  <div class="home-alert">
                    <feather-icon
                      class="alert-icon"
                      icon="BellIcon"
                    />
                    <span>No active alerts</span>
                  </div>
                </div>
              </b-col>
              <b-col cols="12" lg="5" class="home-right-side">
                <b-card-title class="text-status mb-0">Programa em curso</b-card-title>
                <div class="program-icons">
                  <div class="icon-program">
                    <img src="@/assets/images/icons/blastfreeze.png" alt="">
                    <span>Blast freeze</span>
                  </div>
                  <div class="icon-program">
                    <img src="@/assets/images/icons/hard.png" alt="">
                    <span>Hard</span>
                  </div>
                  <div class="icon-program mr-0">
                    <img src="@/assets/images/icons/time.png" alt="">
                    <span>Time</span>
                  </div>
                </div>
              </b-col>
              <b-col cols="12" lg="2" class="icon-ventilation">
                <div class="icon-vent mr-0">
                  <img src="@/assets/images/icons/active-ventilation.png" alt="">
                  <b-button
                    class="vent-btn mr-0"
                    variant="none"
                  >
                    <span>Ver mais</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardText, BButton, BRow, BCol, BImg, BCardBody, BCardTitle, BCardSubTitle, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BRow,
    BCol,
    BLink,
    BImg,
  },
  directives: {
    Ripple,
  },
}
</script>
