<template>
  <div>
    <b-row class="match-height">
      <b-col
        cols="12"
        lg="12"
      >
        <b-card class="home-general">
          <b-row>
            <b-col cols="12" lg="6">
              <div class="general-left">
                <span class="text-status">
                  General Status
                </span>
                <b-form-group
                >
                  <v-select
                    class="status-select"
                    placeholder="Now"
                  />
                </b-form-group>
              </div>
              <span>
                <feather-icon
                  class="download-icon"
                  icon="DownloadIcon"
                />
              </span>
              <b-row>
                <b-col cols="12" xl="9">
                  <div class="range-section">
                    <div class="status-range">
                      <vue-slider
                        direction="btt"
                        class="range"
                      />
                      <span>10</span>
                    </div>
                    <div class="status-range">
                      <vue-slider
                        direction="btt"
                        class="range range-2"
                      />
                      <span>7</span>
                    </div>
                    <div class="status-range">
                      <vue-slider
                        direction="btt"
                        class="range range-3"
                      />
                      <span>5</span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="12" xl="3">
                  <div class="status-sub">
                    <span>
                      <feather-icon
                        class="circle-icon green"
                        icon="CircleIcon"
                      />
                      Nº equipamento ativo
                    </span>
                    <span>
                      <feather-icon
                        class="circle-icon red"
                        icon="CircleIcon"
                      />
                      Nº equipamento com alertas
                    </span>
                    <span>
                      <feather-icon
                        class="circle-icon gray"
                        icon="CircleIcon"
                      />
                      Nº equipamento offline
                    </span>
                  </div>
                </b-col>
              </b-row>
            </b-col>
            <b-col lg="6">
              <div class="general-left">
                <span class="text-status">
                  General Status by site
                </span>
                <b-form-group
                >
                  <v-select
                    class="status-select"
                    placeholder="Site"
                  />
                </b-form-group>
              </div>
              <span>
                <feather-icon
                  class="download-icon"
                  icon="DownloadIcon"
                />
              </span>
              <b-row>
                <b-col cols="12" xl="9">
                  <div class="range-section">
                    <div class="status-range">
                      <vue-slider
                        direction="btt"
                        class="range"
                      />
                      <span>10</span>
                    </div>
                    <div class="status-range">
                      <vue-slider
                        direction="btt"
                        class="range range-2"
                      />
                      <span>7</span>
                    </div>
                    <div class="status-range">
                      <vue-slider
                        direction="btt"
                        class="range range-3"
                      />
                      <span>5</span>
                    </div>
                  </div>
                </b-col>
                <b-col cols="12" xl="3">
                  <div class="status-sub">
                    <span>
                      <feather-icon
                        class="circle-icon green"
                        icon="CircleIcon"
                      />
                      Nº equipamento ativo
                    </span>
                    <span>
                      <feather-icon
                        class="circle-icon red"
                        icon="CircleIcon"
                      />
                      Nº equipamento com alertas
                    </span>
                    <span>
                      <feather-icon
                        class="circle-icon gray"
                        icon="CircleIcon"
                      />
                      Nº equipamento offline
                    </span>
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BCardText, BButton, BRow, BCol, BImg, BCardBody, 
  BCardTitle, BCardSubTitle, BLink, VBTooltip, BFormGroup
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import VueSlider from 'vue-slider-component'

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BFormGroup,
    BRow,
    BCol,
    BLink,
    BImg,
    VueSlider,
    VBTooltip,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data(){
    return {
      value: 10,
      value2: 7,
      value3: 5,
    }
  }
}
</script>